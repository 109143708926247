import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import LoginSection from "../../components/Login/LoginSection";

const LoginPage = () => {
  return (
    <>
      <Helmet>
        <title>Login - Access Your Brainstake eSchools Account</title>
        <meta
          name="description"
          content="Sign in to Brainstake eSchools to manege your account"
        />
      </Helmet>

      <div className="h-fit gap-5 mt-10 bg-[url(/public/images/bg.png)] bg-cover bg-no-repeat">
        <div className="items-center justify-center p-2">
          <Header className="sm:ml-5" />
        </div>

        <div className="w-[60%] sm:w-full md:w-[75%] items-center justify-center mx-auto">
          <LoginSection />
        </div>
        <div className="mt-5 mx-auto w-[94%] md:w-full sm:w-full">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LoginPage;
