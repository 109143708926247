import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { imageIcons } from '../../../components/imageComponents';
import '../../../css/mainDashboard.css';
import SchoolEnrolmentDetails from './schoolEnrolmentDetails';
import EditSchoolEnrolmentDetails from './editSchoolDetails';
import ApproveEnrolmentModal from './approveEnrolment';
import RejectEnrolmentModal from './rejectEnrolment';
import FetchSchoolRequests from '../../../controllers/superAdminAPIs/fetchSchoolRequests';
import ProgressIndicator from '../../../globalConfigs/progressIndicator';

const SchoolRequests = () => {
    const { schools, loading, error } = FetchSchoolRequests();
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const [sortBy, setSortBy] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [isViewModalOpen, setViewModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isApproveModalOpen, setApproveModalOpen] = useState(false);
    const [isRejectModalOpen, setRejectModalOpen] = useState(false);

    // Event handlers for modals
    const handleViewDetails = (school) => {
        setSelectedSchool(school);
        setViewModalOpen(true);
    };

    const handleEditDetails = (school) => {
        setSelectedSchool(school);
        setEditModalOpen(true);
    };

    const handleApproveClick = (school) => {
        setSelectedSchool(school);
        setApproveModalOpen(true);
    };

    const handleRejectClick = (school) => {
        setSelectedSchool(school);
        setRejectModalOpen(true);
    };

    const closeViewModal = () => {
        setViewModalOpen(false);
        setSelectedSchool(null);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
        setSelectedSchool(null);
    };

    const closeApproveModal = () => {
        setApproveModalOpen(false);
        setSelectedSchool(null);
    };

    const closeRejectModal = () => {
        setRejectModalOpen(false);
        setSelectedSchool(null);
    };

    // Sorting logic
    const sortedData = [...schools].sort((a, b) => {
        if (sortBy === 'name') {
            return a.name.localeCompare(b.name);
        } else if (sortBy === 'date') {
            return new Date(a.createdAt) - new Date(b.createdAt);
        }
        return 0;
    });

    // Filtering logic based on selected status
    const filteredData = filterStatus
        ? sortedData.filter((school) => school.status === filterStatus)
        : sortedData;

    // Pagination logic: slice filtered data for the current page
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

    // Generate page numbers for pagination
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    if (loading) return <div><ProgressIndicator/></div>;
    if (error) return <div>Error: {error.message}</div>;


    return (
        <div className="table-container">
            {/* Controls for sorting and filtering */}
            <div className="controls">
                <label className="inline-label">
                    Showing:
                    <select value={sortBy} onChange={(e) => setSortBy(e.target.value)} className="short-field">
                        <option value="">None</option>
                        <option value="name">Name</option>
                        <option value="date">Date</option>
                    </select>
                </label>

                <label className="inline-label">
                    Filter by:
                    <select value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)} className="short-field">
                        <option value="">None</option>
                        <option value="REJECTED">Rejected</option>
                        <option value="PENDING">Pending</option>
                        <option value="APPROVED">Approved</option>
                    </select>
                </label>
            </div>

            {/* Table */}
            <table>
                <thead>
                    <tr>
                        <th>Name of school</th>
                        <th>Country</th>
                        <th>Admin's email</th>
                        <th>School phone number</th>
                        <th>Ownership Type</th>
                        <th>Status</th>
                        <th>Approve</th>
                        <th>Reject</th>
                        <th>Details</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {currentRows.map((school, index) => (
                        <tr key={index}>
                            <td>{school.name}</td>
                            <td>{school.country}</td>
                            <td>{school.adminEmail}</td>
                            <td>{school.schoolContact || "N/A"}</td>
                            <td>{school.ownershipType || "N/A"}</td>
                            <td>{school.status}</td>
                            <td className="viewMore">
                                <Link to="">
                                    <img
                                        src={imageIcons.approve}
                                        alt="Approve"
                                        className='enquiriesImg'
                                        onClick={() => handleApproveClick(school)}
                                    />
                                </Link>
                            </td>
                            <td className="viewMore">
                                <Link to="">
                                    <img
                                        src={imageIcons.reject}
                                        alt="Reject"
                                        className='enquiriesImg'
                                        onClick={() => handleRejectClick(school)}
                                    />
                                </Link>
                            </td>
                            <td className="viewMore">
                                <Link to="">
                                    <img
                                        src={imageIcons.eye}
                                        alt="Details" onClick={() => handleViewDetails(school)}
                                        className='enquiriesImg'
                                    />
                                </Link>
                            </td>
                            <td className="viewMore">
                                <Link to="">
                                    <img
                                        src={imageIcons.edit}
                                        alt="Edit" onClick={() => handleEditDetails(school)}
                                        className='enquiriesImg'
                                    />
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modals */}
            <SchoolEnrolmentDetails school={selectedSchool} isOpen={isViewModalOpen} onClose={closeViewModal} />
            <EditSchoolEnrolmentDetails school={selectedSchool} isOpen={isEditModalOpen} onClose={closeEditModal} />
            <ApproveEnrolmentModal isOpen={isApproveModalOpen} onClose={closeApproveModal} />
            <RejectEnrolmentModal isOpen={isRejectModalOpen} onClose={closeRejectModal} />

            {/* Pagination */}
            <div className="pagination">
                {pageNumbers.map((number) => (
                    <button key={number} onClick={() => setCurrentPage(number)} className={currentPage === number ? 'active' : ''}>
                        {number}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default SchoolRequests;
