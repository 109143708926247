import React, { useState } from 'react';
import '../../../css/mainDashboard.css';

const ApproveEnrolmentModal = ({ isOpen, onClose, onApprove }) => {
    const [feedback, setFeedback] = useState('');

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    };

    const handleApproveClick = () => {
        // Handle approval logic here, like sending feedback to the server
        onApprove(feedback);
        onClose(); // Close the modal after approval
    };

    if (!isOpen) return null;

    return (
        <div className='divOverlay'>
            <div className='divModal'>
                <div className='modalHeader'>
                    <h2>Approve Enrolment Request</h2>
                    <span className='closeIcon' onClick={onClose}>
                        &times;
                    </span>
                </div>
                <textarea className='divTextArea'
                    value={feedback}
                    onChange={handleFeedbackChange}
                    placeholder="Feedback e.g. feedback after approval"
                />
                <button
                    className='approveButton'
                    onClick={handleApproveClick}
                >
                    Approve request
                </button>
            </div>
        </div>
    );
};


export default ApproveEnrolmentModal;
