/* eslint-disable react/prop-types */

import Button from "./Button";
import Heading from "./Heading";

const WelcomeRightRow = ({
  welcomeText = "Welcome to Brainstake Eschools",
  ...props
}) => {
  return (
    <div {...props} className={`${props?.className} relative h-full w-[85%]`}>
      <div className="relative h-full bottom-px left-0  ml-auto mr-8 flex flex-col flex-1 rounded-[20px] bg-white-a700 px-[54px] py-[22px] md:p-5">
        <Heading
          size="headinglg"
          as={"h3"}
          className="w-[272px] top-0 mb-3 text-[30px] font-bold leading-[45px] text-black-900_01"
        >
          {welcomeText}
        </Heading>
        <div className="absolute bottom-0 right-0 mb-[-30px] mr-[-30px] h-[60px] w-[60px] rounded-[30px] bg-[#000]" />
        <Button className="w-[282px] h-[40px] top-[314px] left-[815px] p-2 mb-1 rounded-[20px]">
          Enrol your school today
        </Button>
      </div>
    </div>
  );
};

export default WelcomeRightRow;
