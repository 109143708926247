import "../../css/admin_left_sidebar_nav.css";
import AdminLink from "../widgets/AdminLink";

const AdminLeftSidebarNav = () => {
  return (
    <div className="left_side_nav">
      <AdminLink
        url_link="/admin/statistics"
        icon_url={"url('/images/chart.png')"}
        description="Statistics"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/student_management"
        icon_url={"url('/images/student_avatar.png')"}
        description="Student Management"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/teacher_management"
        icon_url={"url('/images/teacher_avatar.png')"}
        description="Teacher Management"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/class_management"
        icon_url={"url('/images/classroom.png')"}
        description="Class Management"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/communications_centre"
        icon_url={"url('/images/comments_speech.png')"}
        description="Communications Centre"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/school_events_and_announcements"
        icon_url={"url('/images/announcement.png')"}
        description="School events and Announcements"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/profile_section"
        icon_url={"url('/images/profile.png')"}
        description="Profile Section"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/documents_section"
        icon_url={"url('/images/document.png')"}
        description="Documents Section"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/settings_section"
        icon_url={"url('/images/gear_solid.png')"}
        description="Settings Section"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/getting_started"
        icon_url={"url('/images/getting_started.png')"}
        description="Getting Started"
        is_sidebar_link={true}
      />
    </div>
  );
};

export default AdminLeftSidebarNav;
